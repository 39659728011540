import React, { createContext, useContext, useState, ReactNode } from 'react'

interface MarkAsDoneContextType {
  isDone: boolean
  markedAsDone: (isDone: boolean) => void
}

const MarkAsDoneContext = createContext<MarkAsDoneContextType | null>(null)

export const MarkAsDoneProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isDone, setIsDone] = useState<boolean>(false)

  const markedAsDone = (isDone: boolean) => setIsDone(isDone)

  return (
    <MarkAsDoneContext.Provider value={{ isDone, markedAsDone }}>
      {children}
    </MarkAsDoneContext.Provider>
  )
}

export const useMarkAsDone = (): MarkAsDoneContextType => {
  const context = useContext(MarkAsDoneContext)
  if (!context) {
    throw new Error('useMarkAsDone must be used within a MarkAsDoneProvider')
  }
  return context
}
