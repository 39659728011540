import React, { useState } from 'react'
import { Box, TextField, Checkbox, MenuItem, ListItemText, Popover, Radio } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { MUIButton } from '../../Shared'
import './ListingFilter.module.scss'

interface ListingFilterProps {
  lable: string
  id: string
  searchable: boolean
  clearAll: boolean
  options: { key: string; value: string }[]
  searchFunction?: (filter: any, id: string) => void
  isFilterSingleSelect?: boolean
}

const ListingFilter: React.FC<ListingFilterProps> = ({
  searchable,
  lable,
  id,
  clearAll,
  options,
  searchFunction,
  isFilterSingleSelect,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const [optionsAfterSubmit, setOptionsAfterSubmit] = useState<string[]>([])
  const [selectedOptions, setSelectedOptions] = useState<string[]>(optionsAfterSubmit)
  const [searchTerm, setSearchTerm] = useState('')
  const open = Boolean(anchorEl)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSelectedOptions(optionsAfterSubmit)
  }

  const handleSelectOption = (key: any) => {
    if (options && selectedOptions.includes(key)) {
      setSelectedOptions(selectedOptions.filter((selectedKey) => selectedKey !== key))
    } else if (options) {
      setSelectedOptions([...selectedOptions, key])
    }
  }

  const handleSingleSelect = (key: any) => {
    setSelectedOptions([key])
  }

  const handleClearAll = () => {
    if (optionsAfterSubmit.length > 0) {
      if (searchFunction) searchFunction({}, id)
    }
    setOptionsAfterSubmit([])
    setSelectedOptions([])
    setSearchTerm('')
  }

  const handleCancel = () => {
    setSearchTerm('')
    handleClose()
  }

  const handleSave = () => {
    const filter: any = {}
    if (selectedOptions.length > 0) {
      filter.filterBy = id
      filter.filterCondition = 'in'
      filter.filterValue = selectedOptions
    }
    if (searchFunction) searchFunction(filter, id)
    setOptionsAfterSubmit(selectedOptions)
    setSearchTerm('')
    setAnchorEl(null)
  }

  return (
    <div style={{ position: 'relative', top: '20px' }}>
      <MUIButton
        width={200}
        color='secondary'
        height='40px'
        type='button'
        fontSize={'15px'}
        label={lable}
        variant='outlined'
        endIcon={
          open ? (
            <KeyboardArrowUpIcon sx={{ fontSize: '24px !important' }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ fontSize: '24px !important' }} />
          )
        }
        handleClickCB={handleClick}
        sx={{
          borderRadius: '8px',
          opacity: '0.7',
          backgroundColor: '#ffffff',
          border: optionsAfterSubmit.length > 0 ? '3px solid #402080' : '',
        }}
      />
      <Popover
        data-testid='popover_dialog'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: '10px' }}
      >
        <Box
          className='popover-dialog'
          sx={{ width: '280px', height: '270px', position: 'relative' }}
        >
          <Box
            sx={{
              display: searchable && clearAll ? 'flex' : '',
              justifyContent: 'space-around',
            }}
          >
            <Box sx={{ textAlign: searchable ? 'center' : '' }}>
              {searchable && (
                <TextField
                  placeholder='Search...'
                  value={searchTerm}
                  onChange={handleSearch}
                  sx={{
                    paddingTop: '8px',
                    '& .MuiInputBase-root': {
                      height: '40px',
                      width: '180px',
                    },
                  }}
                />
              )}
            </Box>
            {clearAll && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingTop: '8px',
                  alignContent: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {!searchable && (
                  <Box
                    sx={{
                      alignContent: 'center',
                      fontWeight: '600',
                      fontSize: '16px',
                      paddingLeft: '8px',
                    }}
                  >
                    {lable}
                  </Box>
                )}
                <MUIButton
                  width={50}
                  fontSize={14}
                  height='40px'
                  type='button'
                  label={'Clear All'}
                  variant='text'
                  handleClickCB={handleClearAll}
                />
              </Box>
            )}
          </Box>

          {isFilterSingleSelect ? (
            <Box sx={{ maxHeight: '160px', overflowY: 'scroll' }}>
              {options
                .filter((option) => option.value.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((item) => (
                  <MenuItem
                    key={item.key}
                    value={item.key}
                    onClick={() => handleSingleSelect(item.key)}
                    sx={{ height: '50px' }}
                  >
                    <Radio
                      data-testid={`filter_checkbox_${item.key}`}
                      checked={selectedOptions[0] == item.key}
                    />
                    <ListItemText primary={item.value} />
                  </MenuItem>
                ))}
            </Box>
          ) : (
            <Box sx={{ maxHeight: '160px', overflowY: 'scroll' }}>
              {options
                .filter((option) => option.value.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((item) => (
                  <MenuItem
                    key={item.key}
                    value={item.key}
                    onClick={() => handleSelectOption(item.key)}
                    sx={{ height: '50px' }}
                  >
                    <Checkbox
                      data-testid={`filter_checkbox_${item.key}`}
                      checked={selectedOptions.includes(item.key)}
                    />

                    <ListItemText primary={item.value} />
                  </MenuItem>
                ))}
            </Box>
          )}

          <Box
            className='d-flex'
            sx={{
              position: 'absolute',
              bottom: '0',
              width: 'inherit',
              gap: 2,
              justifyContent: 'end',
              paddingRight: '8px',
              paddingBottom: '6px',
            }}
          >
            <MUIButton
              width={50}
              fontSize={14}
              height='36px'
              type='button'
              label={'Cancel'}
              variant='outlined'
              handleClickCB={handleCancel}
            />
            <MUIButton
              width={50}
              fontSize={14}
              height='36px'
              type='button'
              label={'Submit'}
              variant='contained'
              handleClickCB={handleSave}
            />
          </Box>
        </Box>
      </Popover>
    </div>
  )
}

export default ListingFilter
