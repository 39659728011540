import { useEffect, useState } from 'react'
// import ContentCategory from '../../Components/Home/ContentCategory'
import { HOME_PAGE } from '../../Constants/ApiDefinations'
import { objectToQueryString } from '../../Helpers/Util'
import mainApiService from '../../Services'
import { useAppDispatch } from '../../Store/Hooks'
import { setLoader } from '../../Store/Slice/loader.slice'
import ComingSoon from '../../Assets/Images/coming-soon.gif'

function Home() {
  const dispatch = useAppDispatch()
  const [assetStatusData, setassetStatusData] = useState<any>([])
  const [metaCategoryList, setmetaCategoryList] = useState([])
  // const getHomepageData = async () => {
  //   const payload = {
  //     assetStatus: 'TRUE',
  //     contentCategories: 'TRUE',
  //     assetType: 'TRUE',
  //     publishedAssets: 'TRUE',
  //   }
  //   const paramsData = objectToQueryString(payload)
  //   dispatch(setLoader(true))
  //   const requestedData: any = HOME_PAGE.GET_HOME_PAGE(paramsData)
  //   const result: any = await mainApiService(requestedData)
  //   dispatch(setLoader(false))
  //   if (result?.responseCode === 200) {
  //     setassetStatusData(result.data)
  //   }
  // }
  useEffect(() => {
    // getHomepageData()
  }, [])
  return (
    <div className='homepage-wrapper'>
      <div className='d-flex column-gap-20 coming-soon'>
        {/* <h1>Welcome to Home Page</h1> */}
        <img src={ComingSoon} alt='Coming Soon' />
      </div>
    </div>
  )
}

export default Home
