import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Autocomplete, Checkbox, TextField } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import { Control, FieldErrors } from 'react-hook-form'
import { FieldValidationRulesModal } from '../../../Models'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

interface MUIAutocompleteMultiSelectModal {
  usingFor?: string
  label: string
  LabelIcon?: ReactNode
  options: any
  labelKey: string
  control?: Control
  name?: string
  disabled?: boolean
  error?: FieldErrors
  onInputChange?: any
  rules?: FieldValidationRulesModal
  value?: any
  width?: string
  limitTags?: number
  required?: boolean
  highlighSelectedField?: boolean
  id?: string
  disabledLabelsArr?: any
  disabledOptions?: any
  disabledOptionsByLable?: any
  sx?: any
  multiple?: boolean
  className?: string
  addOptionAll?: boolean
  testId?: string
}

const MUIAutocompleteMultiSelect: React.FC<MUIAutocompleteMultiSelectModal> = ({
  usingFor = '',
  label,
  LabelIcon,
  options = [],
  labelKey,
  name = '',
  error,
  onInputChange,
  value,
  width = '100%',
  limitTags = 1,
  required = false,
  highlighSelectedField = true,
  id = 'checkboxes-tags-demo',
  disabledOptions = [],
  disabledOptionsByLable,
  sx = {},
  multiple = true,
  disabled = false,
  className = '',
  addOptionAll = false,
  testId = '',
}: MUIAutocompleteMultiSelectModal) => {
  const [inputValue, setInputValue] = useState('')
  const [selectedOptions, setSelectedOptions] = useState(value || [])
  const [isAllSelected, setIsAllSelected] = useState(false)

  const handleSearchChange = (_event: any, value: string, reason: string) => {
    if (reason !== 'reset') {
      setInputValue(value)
    }
  }

  const handleOptionChange = (_event: any, newValue: any[]) => {
    if (isAllSelected) {
      if (newValue?.some((item) => item[labelKey] === 'All')) {
        const updatedOptions = newValue.filter((item) => item[labelKey] !== 'All')
        setSelectedOptions(updatedOptions)
        onInputChange(_event, updatedOptions)
        setIsAllSelected(false)
      } else {
        setSelectedOptions([])
        onInputChange(_event, [])
        setIsAllSelected(false)
      }
    } else if (newValue?.some((item) => item[labelKey] === 'All')) {
      setIsAllSelected(true)
      const allOptions = [{ [labelKey]: 'All' }, ...options]
      setSelectedOptions(allOptions)
      const updatedOptions = allOptions.filter((item) => item[labelKey] !== 'All')
      onInputChange(_event, updatedOptions)
    } else {
      const updatedOptions = newValue.filter((item) => item[labelKey] !== 'All')
      setSelectedOptions(updatedOptions)
      onInputChange(_event, updatedOptions)
      setIsAllSelected(false)
    }
  }

  return (
    <Autocomplete
      disabled={disabled}
      isOptionEqualToValue={(option, value) => {
        if (usingFor === 'metaAttributes') {
          return option.fieldName === value.fieldName
        }
        if (usingFor === 'attributePartnersName' || usingFor === 'assetTypeAllowedParents') {
          return option.key === value.key
        }
        if (usingFor === 'assetTypeTollgates') {
          return option.categoryName === value.categoryName
        }
        if (
          (labelKey && option && value && option[labelKey] === value[labelKey]) ||
          (!labelKey && option === value)
        ) {
          return true
        }
        return false
      }}
      {...(multiple
        ? {
            inputValue,
            onInputChange: handleSearchChange,
            onClose: () => {
              setInputValue('')
            },
          }
        : {
            onInputChange: onInputChange,
          })}
      multiple={multiple}
      id={id}
      options={addOptionAll ? [{ [labelKey]: 'All' }, ...options] : options}
      disableCloseOnSelect={multiple}
      limitTags={limitTags}
      getLimitTagsText={(more: number) => `+${more} other${more > 1 ? 's' : ''}`}
      getOptionDisabled={(option: any) => {
        return disabledOptions.includes(option?.[disabledOptionsByLable]) ? true : false
      }}
      data-testid={testId}
      getOptionLabel={(option: any) => option?.[labelKey] ?? option}
      value={addOptionAll ? selectedOptions : value}
      onChange={addOptionAll ? handleOptionChange : onInputChange}
      renderOption={(props, option, { selected }, ownerState) => {
        if (usingFor === 'assetTypeMetaCategory') {
          if (!selected) {
            const selectedValues = ownerState?.value?.map(
              (item: any) => item?.key || item?.fieldName || item?.[labelKey],
            )
            if (selectedValues?.includes(option?.key || option?.fieldName || option?.[labelKey])) {
              selected = true
            }
          }
        }
        return (
          <li
            {...props}
            key={option._id}
            style={highlighSelectedField ? {} : { backgroundColor: selected ? 'transparent' : '' }}
          >
            {multiple && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
            {option?.[labelKey] ?? option}
          </li>
        )
      }}
      fullWidth
      sx={{ width: width, ...sx }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          error={!!error?.[name]}
          helperText={error?.[name]?.message as string}
          label={
            <div className='d-flex align-items-center'>
              {LabelIcon && <div className='d-flex align-items-center'>{LabelIcon}</div>}
              <div style={{ marginLeft: 5 }}>
                {' '}
                <span>
                  {label}
                  {required && <span style={{ color: '#D4351C' }}>*</span>}
                </span>
              </div>
            </div>
          }
        />
      )}
      popupIcon={<KeyboardArrowDownIcon />}
      className={className}
      noOptionsText={<span data-testid='no-options'>No options </span>}
    />
  )
}

export default MUIAutocompleteMultiSelect
