import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const MUIDateTimePicker = ({
  label,
  type,
  onChange,
  value,
}: {
  label: string
  type: string
  onChange: any
  value: any
}) => {
  return (
    <>
      {type === 'date' && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={label}
            sx={{ width: '100%' }}
            onChange={onChange}
            value={value}
            slotProps={{
              textField: {
                helperText: '',
                error: false,
              },
            }}
          />
        </LocalizationProvider>
      )}
      {type === 'time' && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label={label}
            sx={{ width: '100%' }}
            onChange={onChange}
            value={value}
            slotProps={{
              textField: {
                helperText: '',
                error: false,
              },
            }}
          />
        </LocalizationProvider>
      )}
      {type === 'datetime' && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label={label}
            sx={{ width: '100%' }}
            onChange={onChange}
            value={value}
            slotProps={{
              textField: {
                helperText: '',
                error: false,
              },
            }}
          />
        </LocalizationProvider>
      )}
    </>
  )
}

export default MUIDateTimePicker
