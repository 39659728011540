import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WIDGET_MANAGEMENT_CRUD } from '../../Constants/ApiDefinations/index'
import { MANAGE_WIDGET } from '../../Constants/ManageWidget'
import { IManageWidgetSlice, ManageWidgetCategory } from '../../Models/ManageWidget'
import mainApiService from '../../Services'
const { WIDGET_CATEGORIES, PRESENTATION_METADATA, BEHAVIOR, FEATURE_LATER, WIDGET_DATA_ENGINE } =
  MANAGE_WIDGET
const initialState: IManageWidgetSlice = {
  widgetDefinitionId: '',
  widgetDetailId: '',
  widgetDefinitionDetails: {},
  widgetDetails: {},
  dataEngineData: {
    [WIDGET_DATA_ENGINE[0].key]: null,
    [WIDGET_DATA_ENGINE[1].key]: null,
    [WIDGET_DATA_ENGINE[2].key]: null,
  },
  dataEngine: {
    key: '',
    title: '',
    selectedAssets: [],
    selectedAssetDetails: [],
    newlySelectedAssets: [],
  },
  metaData: {
    [PRESENTATION_METADATA[0].key]: { title: PRESENTATION_METADATA[0].title, value: null },
    [PRESENTATION_METADATA[1].key]: { title: PRESENTATION_METADATA[1].title, value: null },
    [PRESENTATION_METADATA[2].key]: { title: PRESENTATION_METADATA[2].title, value: null },
    [PRESENTATION_METADATA[3].key]: { title: PRESENTATION_METADATA[3].title, value: null },
  },
  checklist: {
    [WIDGET_CATEGORIES[0].title]: false,
    [WIDGET_CATEGORIES[1].title]: false,
    [WIDGET_CATEGORIES[2].title]: false,
    featureScheduleType: null,
  },
  featureLater: {
    [FEATURE_LATER[0].key]: { title: FEATURE_LATER[0].title, value: null },
    [FEATURE_LATER[1].key]: { title: FEATURE_LATER[1].title, value: null },
    [FEATURE_LATER[2].key]: { title: FEATURE_LATER[2].title, value: null },
    [FEATURE_LATER[3].key]: [
      { title: 'Day 1', value: { day: null, startDateTime: null, endDateTime: null } },
    ],
  },
}
const manageWidgetSlice = createSlice({
  name: 'manageWidget',
  initialState,
  reducers: {
    setWidgetDefinitionId: (state, action: PayloadAction<string>) => {
      state.widgetDefinitionId = action.payload
    },
    setWidgetDetailId: (state, action: PayloadAction<string>) => {
      state.widgetDetailId = action.payload
    },
    setWidgetDefinitionDetails: (state, action: PayloadAction<{ type: string; value: any }>) => {
      const { type, value } = action.payload
      if (type === 'add') {
        state.widgetDefinitionDetails = { ...value }
      } else {
        state.widgetDefinitionDetails = {}
      }
    },
    setWidgetDetails: (state, action: PayloadAction<{ type: string; value: any }>) => {
      const { type, value } = action.payload
      if (type === 'add') {
        state.widgetDetails = { ...value }
      } else {
        state.widgetDetails = {}
      }
    },
    setDataEngine: (state, action: PayloadAction<ManageWidgetCategory>) => {
      const { title, key } = action.payload
      const { dataEngine } = state
      dataEngine.title = title
      dataEngine.key = key
      state.checklist[WIDGET_CATEGORIES[0].title] = true
    },
    setMetaData: (state, action: PayloadAction<{ key: string; value: any }>) => {
      const { key, value } = action.payload
      if (Object.prototype.hasOwnProperty.call(state.metaData, key)) {
        state.metaData[key].value = value
        state.checklist[WIDGET_CATEGORIES[1].title] = true
      }
    },
    setFeatureType: (state, action: PayloadAction<any>) => {
      state.checklist.featureScheduleType = action.payload
    },
    setFeatureLater: (state, action: PayloadAction<{ key: string; value: any }>) => {
      const { key, value } = action.payload
      const { featureLater } = state
      if (Object.prototype.hasOwnProperty.call(featureLater, key)) {
        const featureLaterKey = FEATURE_LATER[3].key
        const featureLaterValue = featureLater[featureLaterKey]
        if (key !== featureLaterKey && !Array.isArray(featureLaterValue)) {
          ;(featureLater[key] as { title: string; value: string | null }).value = value
          state.checklist[WIDGET_CATEGORIES[2].title] = true
        }
      }
    },
    setSelectedAssets: (state, action: PayloadAction<any>) => {
      if (Array.isArray(action.payload)) {
        state.dataEngine.selectedAssets = action.payload
      } else if (typeof action.payload === 'object') {
        state.dataEngine.selectedAssets.push(action.payload)
      }
    },
    setNewlySelectedAssets: (state, action: PayloadAction<any>) => {
      if (Array.isArray(action.payload)) {
        state.dataEngine.newlySelectedAssets = action.payload
      } else if (typeof action.payload === 'object') {
        state.dataEngine.newlySelectedAssets.push(action.payload)
      }
    },
    clearNewlySelectedAssets: (state) => {
      state.dataEngine.newlySelectedAssets = []
    },
    setSelectedAssetDetails: (state, action: PayloadAction<any[]>) => {
      const existingIds = new Set(
        state.dataEngine.selectedAssetDetails?.map((asset) => getIDKey(asset)),
      )
      // Create updatedAssets array by retaining the order of action.payload
      const updatedAssets = action.payload.map((asset) => {
        const assetId = getIDKey(asset)
        // If asset already exists, use the existing object; otherwise, use the new asset
        return existingIds.has(assetId)
          ? state.dataEngine.selectedAssetDetails.find((a) => getIDKey(a) === assetId)
          : asset
      })
      // Set selectedAssetDetails to the ordered updatedAssets array
      state.dataEngine.selectedAssetDetails = updatedAssets
    },
    replaceSelectedAssetDetails: (state, action: PayloadAction<any[]>) => {
      state.dataEngine.selectedAssetDetails = action.payload
    },
    clearSelectedAssetDetails: (state) => {
      state.dataEngine.selectedAssetDetails = []
    },
    resetWidgetData: () => initialState,
  },
})
export const {
  setDataEngine,
  clearNewlySelectedAssets,
  clearSelectedAssetDetails,
  replaceSelectedAssetDetails,
  setSelectedAssets,
  setNewlySelectedAssets,
  setMetaData,
  setFeatureType,
  setFeatureLater,
  setSelectedAssetDetails,
  setWidgetDefinitionDetails,
  setWidgetDefinitionId,
  setWidgetDetailId,
  setWidgetDetails,
  resetWidgetData,
} = manageWidgetSlice.actions
export default manageWidgetSlice
export const manageWidgetSelector = (state: any) => state.manageWidget
export const setWidgetDefinitionIdSelector = createSelector(
  [manageWidgetSelector],
  (parameters) => parameters.widgetDefinitionId,
)
export const getIDKey = (asset: any): string | undefined => {
  return asset?.contentId || asset?.assetId || asset?.containerId || asset?.budgetId || asset?.id
}
export const setWidgetDetailIdSelector = createSelector(
  [manageWidgetSelector],
  (parameters) => parameters.widgetDetailId,
)
export const dataEngineSelector = createSelector(
  [manageWidgetSelector],
  (parameters) => parameters.dataEngine,
)
export const metaDataSelector = createSelector(
  [manageWidgetSelector],
  (parameters) => parameters.metaData,
)
export const checklistSelector = createSelector(
  [manageWidgetSelector],
  (parameters) => parameters.checklist,
)
export const featureLaterSelector = createSelector(
  [manageWidgetSelector],
  (parameters) => parameters.featureLater,
)
export const selectedAssetsList = createSelector(
  [manageWidgetSelector],
  (parameters) => parameters.dataEngine.selectedAssets,
)
export const selectedAssetsDetailsList = createSelector(
  [manageWidgetSelector],
  (parameters) => parameters.dataEngine.selectedAssetDetails,
)
export const newlySelectedAssetsList = createSelector(
  [manageWidgetSelector],
  (parameters) => parameters.dataEngine.newlySelectedAssets,
)
export const widgetDefinitionData = createSelector(
  [manageWidgetSelector],
  (parameters) => parameters.widgetDefinitionDetails,
)
export const widgetDetailsSelector = createSelector(
  [manageWidgetSelector],
  (parameters) => parameters.widgetDetails,
)

export const getWidgetListAPICall = createAsyncThunk(
  'manageWidget/widgetList',
  async (payload: any, { dispatch }) => {
    const requestedData: any = WIDGET_MANAGEMENT_CRUD.LISTING(payload)
    const data: any = await mainApiService(requestedData)
    return data
  },
)
export const assetList = createAsyncThunk(
  'manageWidget/assetList',
  async (data: string, { dispatch }) => {
    const requestedData: WidgetCRUDAPIDefinitionModal = WIDGET_MANAGEMENT_CRUD.ASSET_LISTING(data)
    const result = await mainApiService(requestedData)
    return result
  },
)

export const assetTypes = createAsyncThunk(
  'manageWidget/assetTypes',
  async (data: string, { dispatch }) => {
    const requestedData: WidgetCRUDAPIDefinitionModal = WIDGET_MANAGEMENT_CRUD.TYPES_LISTING()
    const result = await mainApiService(requestedData)
    return result
  },
)
export const assetLayouts = createAsyncThunk(
  'manageWidget/assetLayouts',
  async (widgetType: string, { dispatch }) => {
    const requestedData: WidgetCRUDAPIDefinitionModal =
      WIDGET_MANAGEMENT_CRUD.LAYOUTS_LISTING(widgetType)
    const result = await mainApiService(requestedData)
    return result
  },
)
export const getAssetsDetailByIds = createAsyncThunk(
  'manageWidget/getAssetsDetailByIds',
  async (data: any, { dispatch }) => {
    const requestedData: WidgetCRUDAPIDefinitionModal =
      WIDGET_MANAGEMENT_CRUD.GET_ASSETS_DETAIL_BY_IDS(data.data)
    const result: any = await mainApiService(requestedData)
    if (data.mode === 'replace') {
      dispatch(replaceSelectedAssetDetails(result?.data?.records))
    } else {
      dispatch(setSelectedAssetDetails(result?.data?.records))
    }
    return result
  },
)
export const getWidgetDefinitionDetailById = createAsyncThunk(
  'manageWidget/getWidgetDefinitionDetailById',
  async (data: string, { dispatch }) => {
    const requestedData: WidgetCRUDAPIDefinitionModal =
      WIDGET_MANAGEMENT_CRUD.GET_WIDGET_DEFINITION_BY_ID(data)
    const result: any = await mainApiService(requestedData)
    dispatch(setWidgetDefinitionDetails({ type: 'add', value: result?.data }))
    return result
  },
)
export const getWidgetDetailById = createAsyncThunk(
  'manageWidget/getWidgetDetailById',
  async (data: string, { dispatch }) => {
    const requestedData: WidgetCRUDAPIDefinitionModal =
      WIDGET_MANAGEMENT_CRUD.GET_WIDGET_DETAILS_BY_ID(data)
    const result: any = await mainApiService(requestedData)
    const resultData = result?.data
    if (resultData?.data?.engine === 'AUTOMATED' || resultData?.data?.engine === 'RECOMMENDATION') {
      // resultData = {
      //   ...resultData,
      //   data: {
      //     ...resultData?.data,
      //     query: resultData?.data?.query,
      //     pinnedAssetIds: resultData?.data?.tabs[0]?.tabContent?.pinnedAssetIds,
      //     parameters: resultData?.data?.tabs[0]?.tabContent?.parameters,
      //     contentInfo: resultData?.data?.tabs[0]?.tabContent?.contentInfo,
      //   },
      // }

      resultData.data.query = Object.entries(resultData.data.query).reduce(
        (acc: any, [key, value]: any) => {
          if (Array.isArray(value)) {
            value = value.map((item: any) => {
              const { parameterId } = item
              if (parameterId && typeof parameterId === 'object') {
                return {
                  ...item,
                  parameterId: parameterId._id,
                  dataType: parameterId.dataType,
                  fieldType: parameterId.fieldType,
                }
              }
              return item
            })
          }
          acc[key] = value
          return acc
        },
        {},
      )
    }
    dispatch(setWidgetDetails({ type: 'add', value: resultData }))
    return result
  },
)
export interface WidgetCRUDAPIDefinitionModal {
  url: string
  method: string
  data: any
}
