import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, List } from 'ckeditor5'
import { TextEditorModel } from '../../../Models'

import 'ckeditor5/ckeditor5.css'

const TextEditor: React.FC<TextEditorModel> = ({ defaultValue = '', handleTextEditorData }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        toolbar: ['bold', 'italic', 'bulletedList', 'numberedList'],
        plugins: [Bold, Essentials, Italic, Mention, Paragraph, List],
        licenseKey: '<YOUR_LICENSE_KEY>',
      }}
      data={defaultValue} // Set initial data here directly
      onReady={(editor) => {
        // Set initial content on the editor when it's ready
        editor.setData(defaultValue)
      }}
      onChange={handleTextEditorData}
    />
  )
}

export default TextEditor
