import React, { useState } from 'react'
import { BackButton, Breadcrums, MUIButton } from '../../../Components/Shared'
import CONSTANTS, { BREADCRUMS, PAGES_ROUTES } from '../../../Constants'
import AdvanceSearchStyles from '../AdvanceSearch.module.scss'
import { useAppDispatch } from '../../../Store/Hooks'
import { useNavigate } from 'react-router-dom'
import { BreadcrumsModel, ListingServiceModal } from '../../../Models'
import ListingComponent from '../../../Components/Molecule/ListingComponent'
import { setLoader } from '../../../Store/Slice/loader.slice'
import { combineErrorMessage, objectToQueryString } from '../../../Helpers/Util'
import mainApiService from '../../../Services'
import { ADVANCE_SEARCH } from '../../../Constants/ApiDefinations'
import { showAlert } from '../../../Store/Slice/alert.slice'
import { IconButton, Typography } from '@mui/material'
import { DeleteIcon } from '../../../Components/Icons'
import ConfirmDialog from '../../../Components/Shared/Dialog/ConfirmDialog'
import AdvancedSearch from '..'
const SavedSearchListing = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [dataCount, setdataCount] = useState(0)
  const [listData, setListData] = useState<any[]>([])
  const [pageCount, setPageCount] = useState(0)
  const [idToDelete, setIdToDelete] = useState('')
  const [itemTitle, setItemTitle] = useState('')
  const getList = async (options: ListingServiceModal) => {
    const paramsData = objectToQueryString(options)
    dispatch(setLoader(true))
    const requestedData: any = ADVANCE_SEARCH.SAVED_SEARCH_LIST(paramsData)
    const result: any = await mainApiService(requestedData)
    if (result?.responseCode === 200) {
      setdataCount(result?.data?.count)
      const tempData: any[] = []
      result?.data?.records?.forEach((ele: any) => {
        dispatch(setLoader(false))
        tempData.push({
          _id: ele?._id,
          name: ele?.name,
          isPinnedToDashboard: ele?.isPinnedToDashboard ? 'Yes' : 'No',
          payload: ele?.payload,
          action: (
            <div
              onClick={(e) => {
                e.stopPropagation()
                setItemTitle(ele?.name)
                setIdToDelete(ele?._id)
              }}
            >
              <Typography color={'red'}>Remove</Typography>
            </div>
          ),
        })
      })
      setListData(tempData)
      dispatch(setLoader(false))
    } else {
      dispatch(showAlert([true, combineErrorMessage(result), 'error']))
      dispatch(setLoader(false))
    }
  }
  const handleDelete = async () => {
    let response: any
    try {
      dispatch(setLoader(true))
      const apiRequest = ADVANCE_SEARCH.DELETE_SAVED_SEARCH(idToDelete)
      response = await mainApiService(apiRequest)
    } catch (error) {
      dispatch(showAlert([true, 'An error occurred while deleting the schedule.', 'error']))
    } finally {
      setIdToDelete('')
      setItemTitle('')
      const list: any[] = listData.filter((item) => item._id !== idToDelete)
      setListData(list)
      navigate(`/${PAGES_ROUTES.AdvancedSearch}/${PAGES_ROUTES.SavedSearch}`)
    }
  }
  return (
    <>
      <div className={`${AdvanceSearchStyles.page_component}`}>
        <Breadcrums breadcrums={BREADCRUMS?.ADVANCED_SEARCH_BREADCRUMS() as BreadcrumsModel[]} />
        <div className={`${AdvanceSearchStyles.back_button_advance_search}`}>
          <BackButton
            label='Saved Search'
            className='col73'
            handleOnClick={() =>
              navigate(`/${PAGES_ROUTES.AdvancedSearch}`, { state: { fromSavedSearch: true } })
            }
          />
        </div>
        <ListingComponent
          showCreateButton={false}
          showSearchField={false}
          columns={CONSTANTS.SAVED_SEARCH_LISTING_COLUMNS}
          tableRows={listData}
          dataCount={dataCount}
          searchFunction={(search, sorttype, coltype, filter, pagenumber, pagecount) => {
            setPageCount(pagenumber)
            getList({
              sortBy: sorttype && coltype,
              filterCondition: filter.filterBY,
              filterValue: search,
              filterBy: filter.filterText,
              sortType: sorttype,
              page: pagenumber,
              limit: pagecount,
            })
          }}
          handleRowClick={(e) => {
            navigate(`/${PAGES_ROUTES.AdvancedSearch}`, {
              state: {
                name: e?.name,
                payload: e?.payload,
                isPinnedToDashboard: e?.pinned?.toLowerCase() === 'yes',
                id: e?._id,
              },
            })
          }}
          paginationOption={[
            { value: 10, label: 10 },
            { value: 20, label: 20 },
            { value: 50, label: 50 },
          ]}
        />
        <ConfirmDialog
          width={778}
          description={`Are you sure you want to remove ${itemTitle}?`}
          onClose={() => {
            setIdToDelete('')
            setItemTitle('')
          }}
          onConfirm={() => handleDelete()}
          btn1Label='Cancel'
          btn2Label='Delete'
          btn1Color='secondary'
          btn2Color='error'
          open={idToDelete?.length > 0}
          title='Remove Save & Pin Search'
          icon={'DeleteRoleIcon'}
        />
      </div>
    </>
  )
}
export default SavedSearchListing
