import { CONSTANTS } from './../../Constants/Constant/index'
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PARAMETER_MANAGEMENT_CRUD, WIDGET_MANAGEMENT_CRUD } from '../../Constants/ApiDefinations'
import mainApiService from '../../Services'

const initialState: any = {
  fetchedAssetsList: [],
  payloadForSaveAction: {},
  pinnedAssetIdsForSaveAction: [],
}

const dataEngineCategorySlice = createSlice({
  name: 'dataEngineCategory',
  initialState,
  reducers: {
    setFetchedAssetsList: (state, action: PayloadAction<any>) => {
      state.fetchedAssetsList = action.payload
    },
    clearFetchedAssetsList: (state) => {
      state.fetchedAssetsList = []
    },
    setStorePayloadForSaveAction: (state, action: PayloadAction<any>) => {
      state.payloadForSaveAction = action.payload
    },
    clearPayloadForSaveAction: (state) => {
      state.payloadForSaveAction = {}
      state.pinnedAssetIdsForSaveAction = []
    },
    setStorePinnedAssetIdsForSaveAction: (state, action: PayloadAction<any>) => {
      state.pinnedAssetIdsForSaveAction = action.payload
    },
  },
})
export const {
  setFetchedAssetsList,
  setStorePayloadForSaveAction,
  clearPayloadForSaveAction,
  setStorePinnedAssetIdsForSaveAction,
  clearFetchedAssetsList,
} = dataEngineCategorySlice.actions

const dataEngineCategorySelector = (state: { dataEngineCategory: any }) => state.dataEngineCategory

export const selectFetchedAssetsList = createSelector(
  dataEngineCategorySelector,
  (state) => state.fetchedAssetsList,
)

export const selectPayloadForSaveAction = createSelector(
  dataEngineCategorySelector,
  (state) => state.payloadForSaveAction,
)

export const selectPinnedAssetIdsForSaveAction = createSelector(
  dataEngineCategorySelector,
  (state) => state?.pinnedAssetIdsForSaveAction,
)

export default dataEngineCategorySlice

export const getMastersData = createAsyncThunk(
  'dataEngineCategory/getMastersData',
  async (payload: any, { dispatch }) => {
    const requestedObj: any = {}
    if (payload?.url?.includes('system-configurations') || payload?.url?.includes('blitz-master')) {
      requestedObj['url'] = payload?.url
      requestedObj['method'] = CONSTANTS.HTTP_METHOD.GET
      requestedObj['data'] = {}
    } else {
      requestedObj['url'] = payload?.url
      requestedObj['method'] = CONSTANTS.HTTP_METHOD.POST
      requestedObj['data'] = payload?.body
    }
    const requestedData: any = requestedObj
    const data: any = await mainApiService(requestedData)
    return {
      key: payload,
      data: data,
    }
  },
)

export const getParameterRepositoryDetail = createAsyncThunk(
  'dataEngineCategory/getParameterRepositoryDetail',
  async (payload: any, { dispatch }) => {
    const requestedData: any = PARAMETER_MANAGEMENT_CRUD.VIEW_PARAMETER(payload)
    const data: any = await mainApiService(requestedData)
    return data
  },
)

export const submitDataEngineAutomatedFormAPICall = createAsyncThunk(
  'dataEngineCategory/submitDataEngineAutomatedFormAPICall',
  async (payload: any, { dispatch }) => {
    const requestedData: any = WIDGET_MANAGEMENT_CRUD.AUTOMATTED_ADD(payload)
    const data: any = await mainApiService(requestedData)
    return data
  },
)

export const saveWidgetDataCategory = createAsyncThunk(
  'dataEngineCategory/saveWidgetDataCategory',
  async (payload: any, { dispatch }) => {
    const requestedData: any = WIDGET_MANAGEMENT_CRUD.WIDGET_AUTOMATTED_ADD(payload)
    const data: any = await mainApiService(requestedData)
    return data
  },
)

export const updateWidgetDataCategory = createAsyncThunk(
  'dataEngineCategory/updateWidgetDataCategory',
  async (payload: any, { dispatch }) => {
    const requestedData: any = WIDGET_MANAGEMENT_CRUD.WIDGET_AUTOMATTED_UPDATE(payload)
    const data: any = await mainApiService(requestedData)
    return data
  },
)
