import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CONSTANTS, { BREADCRUMS, PAGES_ROUTES } from '../../../Constants'
import { getPageTitle } from '../../../Helpers/Util'
import { BreadcrumsModel } from '../../../Models'
import { BackButton, Breadcrumbs } from '../../../Components/Shared'
import TipsInformation from '../../../Components/Tips/TipsInformation'
import TipsDetailForm from '../../../Components/Tips/TipsDetailForm'
import { useForm } from 'react-hook-form'
import { useAppDispatch } from '../../../Store/Hooks'
import { setLoader } from '../../../Store/Slice/loader.slice'
import { MANAGE_TIPS } from '../../../Constants/ApiDefinations'
import mainApiService from '../../../Services'
import { showAlert } from '../../../Store/Slice/alert.slice'

interface tipsDetailModel {
  key?: {
    key?: string
    value?: string
  }
  value?: string
  module?: {
    key?: string
    value?: string
  }
  description?: string
  _id?: string
}

const CreateUpdateTips: React.FC = () => {
  const { id, tipsId } = useParams<{ id: string | undefined; tipsId: string | undefined }>()
  const [textEditorValue, setTextEditorValue] = useState('')
  const [tipsDetail, setTipsDetail] = useState<tipsDetailModel>()
  const navigate = useNavigate()
  const { VIEW, CREATE, EDIT } = CONSTANTS.PAGE_TYPE
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    values: {
      key: id !== CREATE ? tipsDetail?.key?.key : '',
      value: id !== CREATE ? tipsDetail?.value : '',
      module: id !== CREATE ? tipsDetail?.module?.key : '',
    },
  })

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (id !== CREATE) {
      getTipsDetail()
    }
  }, [tipsId, id])

  const getTipsDetail = async () => {
    dispatch(setLoader(true))
    const requestedData = MANAGE_TIPS.TIPS_DETAIL(tipsId)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))
    if (result.responseCode === 200) {
      setTipsDetail(result?.data)
    } else {
      dispatch(showAlert([true, result?.error?.errors?.join(','), 'error']))
    }
  }

  const createTip = async (data: any) => {
    dispatch(setLoader(true))
    const requestedData = MANAGE_TIPS.TIPS_ADD(data)
    const result: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    if (result?.responseCode === 200) {
      dispatch(showAlert([true, result?.message, 'success']))
      navigate(`/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ManageTips}`)
    } else {
      dispatch(showAlert([true, result?.error?.errors?.join(','), 'error']))
    }
  }

  const updateTip = async (data: any) => {
    dispatch(setLoader(true))
    const requestedData = MANAGE_TIPS.TIPS_UPDATE(data)
    const result: any = await mainApiService(requestedData)
    dispatch(setLoader(false))
    if (result?.responseCode === 200) {
      dispatch(showAlert([true, result?.message, 'success']))
      navigate(`/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ManageTips}/view/${tipsDetail?._id}`)
    } else {
      dispatch(showAlert([true, result?.error?.errors?.join(','), 'error']))
    }
  }

  const preparePayload = () => {
    const payload = {
      key: control._formValues.selectedKey || tipsDetail?.key,
      value: control._formValues.value,
      module: control._formValues.selectedModule || tipsDetail?.module,
      description: textEditorValue,
    }

    return payload
  }

  const onSubmit = () => {
    let data: any
    if (id === CREATE) {
      data = preparePayload()
      createTip(data)
    }

    if (!(id === VIEW || id === CREATE)) {
      data = preparePayload()
      const updatePayload = {
        ...data,
        _id: tipsDetail?._id,
      }
      updateTip(updatePayload)
    }
  }

  const handleBackBtnAction = () => {
    if (id === VIEW || id === CREATE)
      navigate(`/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ManageTips}`)

    if (id === EDIT)
      navigate(`/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.ManageTips}/view/${tipsId}`)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-100' autoComplete='off'>
      <div className={`role__editor p-16 ${id !== VIEW ? 'pb-120' : ''}`}>
        <div className='p-8'>
          <Breadcrumbs
            breadcrums={BREADCRUMS.CREATE_TIPS(id, getPageTitle(id as string)) as BreadcrumsModel[]}
          />
        </div>
        <div className='p-8'>
          <BackButton
            label={`${getPageTitle(id as string)} Tips`}
            className='col73'
            handleOnClick={handleBackBtnAction}
          />
        </div>
        <div className='mt-1'>
          <div className='role__editor-wrapper'>
            <div className='role__editor-content p-8'>
              {id === VIEW ? (
                <div className=''>
                  <TipsInformation pageInfo={tipsDetail} />
                </div>
              ) : (
                // tipsDetail &&
                <TipsDetailForm
                  tipId={tipsId}
                  pageId={id}
                  errors={errors}
                  control={control}
                  setValue={setValue}
                  getTextEditrValue={setTextEditorValue}
                  tipsDetail={tipsDetail}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default CreateUpdateTips
