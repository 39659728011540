import React, { useEffect, useState } from 'react'
import { MUIBox, MUIButton, MUIField, MUISelect } from '../../Shared'
import { Grid } from '@mui/material'
import TextEditor from '../../Shared/TextEditor'
import { MANAGE_TIPS, SYSTEM_CONFIG_APIS } from '../../../Constants/ApiDefinations'
import mainApiService from '../../../Services'
import CONSTANTS from '../../../Constants'
import { useAppDispatch } from '../../../Store/Hooks'
import { setLoader } from '../../../Store/Slice/loader.slice'
import { showAlert } from '../../../Store/Slice/alert.slice'

interface TipsDetailFormModal {
  tipId: any
  pageId: any
  errors: any
  control: any
  setValue: any
  getTextEditrValue: any
  tipsDetail: any
}

const TipsDetailForm: React.FC<TipsDetailFormModal> = ({
  tipId,
  pageId,
  errors,
  control,
  setValue,
  getTextEditrValue,
  tipsDetail,
}) => {
  let initialModuleData: any = {}

  if (pageId == 'edit' && tipsDetail) {
    initialModuleData = tipsDetail?.module
  }

  const [masterOptionsValue, setMasterOptionsValue] = useState([])
  const [keyOptionsValue, setKeyOptionsValue] = useState([])
  const [masterValue, setMasterValue] = useState(initialModuleData)
  const dispatch = useAppDispatch()

  useEffect(() => {
    getDataFromApi()
    if (tipsDetail) {
      setMasterValue(tipsDetail?.module)
    }
  }, [])

  useEffect(() => {
    if (tipsDetail && tipsDetail.module && pageId == 'edit') {
      setMasterValue(tipsDetail.module)
      getKeyValue()
    } else {
      getKeyValue()
    }
  }, [tipsDetail, masterValue])

  const getKeyValue = async () => {
    if (Object.keys(masterValue).length > 0) {
      const requestedData = MANAGE_TIPS.MODULE_PAGES(
        control._formValues?.selectedModule?.key || masterValue?.key,
      )
      const keyResult: any = await mainApiService(requestedData)
      if (keyResult?.responseCode === 200) {
        keyResult?.data.forEach((item: any) => delete item._id)
        setKeyOptionsValue(keyResult?.data)
      } else {
        dispatch(showAlert([true, keyResult?.error?.errors?.join(','), 'error']))
      }
    }
  }

  const handleOnChange = (e: any) => {
    setValue('selectedModule', e.selectedOption)
    setMasterValue(control._formValues.selectedModule)
  }

  const getDataFromApi = async () => {
    const requestedData = SYSTEM_CONFIG_APIS.LIST_MASTERS('MODULE_TOOL_TIP')
    const result: any = await mainApiService(requestedData)
    if (result?.responseCode === 200) {
      result?.data.forEach((item: any) => delete item._id)
      setMasterOptionsValue(result?.data)
    } else {
      dispatch(showAlert([true, result?.error?.errors?.join(','), 'error']))
    }
  }

  const handleOnTextData = (event: any, editor: any) => {
    const data = editor.getData()
    getTextEditrValue(data)
  }

  return (
    <MUIBox>
      <div>
        <div className='heading-6 d-flex text-left rolebox__header-padding'>Tips Contents</div>
        <div className='hr'></div>
        <div className='rolebox__content-padding'>
          <Grid container columnSpacing={10} rowSpacing={4}>
            <Grid item xs={6} md={6} lg={6} xl={6}>
              <MUISelect
                name='module'
                lable='Module'
                placeholder='Please select module'
                labelKey={'value'}
                valueKey={'key'}
                control={control}
                error={errors}
                rules={{ required: 'This field is required' }}
                options={masterOptionsValue}
                onChange={handleOnChange}
                required={true}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6} xl={6}>
              <MUISelect
                name='key'
                lable='Key'
                placeholder='Please select key'
                labelKey={'value'}
                valueKey={'key'}
                control={control}
                error={errors}
                rules={{ required: 'This field is required' }}
                options={keyOptionsValue}
                onChange={(e) => setValue('selectedKey', e.selectedOption)}
                required={true}
                disabled={Object.keys(masterValue).length > 0 ? false : true}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6} xl={6}>
              <MUIField
                name='value'
                control={control}
                rules={{ required: 'This field is required' }}
                label='Value'
                required
                type='text'
                error={errors}
                maxLength={100}
              />
            </Grid>

            <Grid item xs={12}>
              <TextEditor
                defaultValue={tipsDetail?.description}
                handleTextEditorData={handleOnTextData}
              />
            </Grid>
            <Grid item xs={12} className='d-flex justify-content-end'>
              <MUIButton
                size='large'
                type={'submit'}
                label={'Submit'}
                variant='contained'
                width={200}
                className='c-btn'
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </MUIBox>
  )
}

export default TipsDetailForm
